import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    getModelOptionsForYear,
    getYearOptions,
    VehicleYmm,
    VehicleYmmGroup,
} from '../../../../../models/vehicle-ymm';
import { buildPath } from '../../../../../routes';
import { RedirectData } from '../../../../../support/components/FMALogin/FMALogin';
import ServerContext from '../../../../../contexts/serverContext';
import siteMapService from '../../../../../support/services/site-map-service/site-map-service';
import { VehicleListServiceAem } from '../../../../../services/vehicle-list-service-aem/vehicle-list-service-aem';
import { getAemVehicleModelData } from '../../../../../hooks/use-aem-vehicle-model-data';
import { useAnalytics } from '../../../../../hooks/use-analytics';
import { Heading } from '../../../../common/heading/heading';
import { pageTypesForH3 } from '../../../../../support/support-constants';
import localStorageWrapper from '../../../../utils/local-storage-wrapper/localStorageWrapper';
import { FMButton, FMDropdown } from '@own/fds-react';
import { FMDropdownProps } from '@own/fds-react/dist/types/fm-dropdown.types';
import './pre-imagine-ymm-selector.scss';
import { FM_BTN_TYPE } from '@own/fds-react/dist/types/fm-button.types';
import { PageType } from '../../../view-template/page-type';

const DEFAULT_VEHICLE_GROUP: VehicleYmmGroup[] = [];
const DEFAULT_YEAR = 0;
const DEFAULT_MODEL = '';

interface Props {
    ymmHeaderLabel?: string;
    yearDropdownLabel: string;
    modelDropdownLabel: string;
    submitLabel: string;
    vehicleDropdownData?: VehicleYmmGroup[];
    redirectData?: RedirectData;
    eventName?: string;
    handleDropDownChange?: (year: number, model: string) => void;
    handleYearModelSubmit?: (
        model: string,
        selectedYear: number,
        vehicleYmm?: VehicleYmm
    ) => void;
    isTabs?: boolean;
    ymmSubmitEvent?: () => void;
    buttonType?: FM_BTN_TYPE;
    page?: PageType;
    submitButtonAriaLabel?: string;
}

const PreImagineYMMSelector = (props: FMDropdownProps & Props) => {
    const {
        root,
        currentLanguageRegionCode,
        currentRegionCode,
        brand,
    } = useContext(ServerContext);
    const currentRoot = root ? root.substring(0, root.length - 1) : '';
    const { handleDropDownChange, handleYearModelSubmit } = props;
    const [modelsByYear, setModelsByYear] = useState<VehicleYmmGroup[]>(
        props.vehicleDropdownData || DEFAULT_VEHICLE_GROUP
    );
    const [selectedYear, setSelectedYear] = useState<number>(DEFAULT_YEAR);
    const [selectedModel, setSelectedModel] = useState<string>(DEFAULT_MODEL);

    const yearOptions: string[] = getYearOptions(modelsByYear);
    const modelOptions = getModelOptionsForYear(modelsByYear, selectedYear);

    const history = useHistory();
    const [fireEvents] = useAnalytics();

    const selectVehicle = async (model: string) => {
        const vehicleYmm = await getAemVehicleModelData(
            brand,
            currentRegionCode,
            currentLanguageRegionCode,
            selectedYear,
            selectedModel,
            'DISPLAY_MODEL_NAME'
        );

        if (model && selectedYear && vehicleYmm?.seoKey) {
            switch (props.redirectData?.id) {
                case 'owner-manual':
                    window.location.href = `${currentRoot}${siteMapService
                        .getPathFromRouterConfigs(
                            currentLanguageRegionCode,
                            brand,
                            'OwnerManualDetailsPage',
                            [vehicleYmm.seoKey, selectedYear.toString()]
                        )
                        ?.replace('+', '')}`;
                    break;
                case 'recalls':
                case 'recalls-details':
                    localStorageWrapper.setItem('USER_VIN', '');
                    history.push(
                        `${buildPath('RecallsDetailsPage')
                            .replace(':vinOrModel?', vehicleYmm.seoKey)
                            .replace(':year?', selectedYear)}`
                    );
                    props.ymmSubmitEvent && props.ymmSubmitEvent();
                    break;
                default:
                    history.push(
                        buildPath(
                            'VehicleYmmView',
                            vehicleYmm.seoKey,
                            String(selectedYear)
                        )
                    );
                    break;
            }
        }
    };

    const onYearDropdownChange = (e: any) => {
        setSelectedYear(parseInt(e.target.value, 10));
    };

    const onModelDropdownChange = (e: any) => {
        setSelectedModel(e.target.value);
        if (handleDropDownChange) {
            handleDropDownChange(selectedYear, e.target.value);
        }
    };

    const onYMMSubmit = async () => {
        if (handleYearModelSubmit) {
            const vehicleYmm = await getAemVehicleModelData(
                brand,
                currentRegionCode,
                currentLanguageRegionCode,
                selectedYear,
                selectedModel,
                'DISPLAY_MODEL_NAME'
            );
            handleYearModelSubmit(selectedModel, selectedYear, vehicleYmm);
        } else {
            selectVehicle(selectedModel);
        }
    };

    useEffect(() => {
        if (!props.vehicleDropdownData) {
            new VehicleListServiceAem()
                .getVehicles(
                    brand,
                    currentRegionCode,
                    currentLanguageRegionCode
                )
                .then(setModelsByYear)
                .catch(console.error);

            return function cleanup() {
                setModelsByYear(DEFAULT_VEHICLE_GROUP);
            };
        }
    }, []);
    const type =
        props.redirectData?.id && pageTypesForH3.includes(props.redirectData.id)
            ? 'h3'
            : 'h2';
    const dropDownsEmpty = !(selectedYear > 0 && selectedModel);
    return (
        <div className="pre-imagine-ymm-vin-container">
            {props.ymmHeaderLabel && (
                <div className="pre-imagine-ymm-vin-header">
                    <Heading type={type} className="selector-label">
                        {props.ymmHeaderLabel}
                    </Heading>
                </div>
            )}
            <div className="pre-imagine-ymm-vin-body">
                <div className="pre-imagine-ymm-container-dropdown">
                    <FMDropdown
                        header={props.yearDropdownLabel}
                        options={yearOptions}
                        onChange={onYearDropdownChange}
                        dataTestId="year-dropdown"
                        id="year-dropdown"
                        ariaLabel={props.yearDropdownLabel}
                    />
                    <FMDropdown
                        ariaLabel={props.modelDropdownLabel}
                        header={props.modelDropdownLabel}
                        options={modelOptions}
                        disabled={!selectedYear}
                        onChange={onModelDropdownChange}
                        dataTestId="model-dropdown"
                        id="model-dropdown"
                    />
                </div>
                <FMButton
                    type={props.buttonType || 'primary'}
                    chevron={
                        props.page === 'Home Page 3.0' ? undefined : 'right'
                    }
                    label={props.submitLabel}
                    disabled={dropDownsEmpty}
                    onClick={() => {
                        if (props.eventName) {
                            fireEvents(
                                props.eventName,
                                undefined,
                                {
                                    ctaType: 'model',
                                },
                                false
                            );
                        }
                        onYMMSubmit();
                    }}
                    data-test-id={'ymm-vehicle-selector-submit-button'}
                    ariaLabel={props.submitButtonAriaLabel || props.submitLabel}
                ></FMButton>
            </div>
        </div>
    );
};

export default PreImagineYMMSelector;
