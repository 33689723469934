import React, { useContext } from 'react';
import SearchBarInput from '../search-bar/search-bar-input/search-bar-input';
import './find-a-solution.scss';
import { FindASolutionFragment } from '../../../models/experiencefragments/find-a-solution';
import { SecondaryButton } from '../../common';
import { LinkWithIcon } from './link-with-icon';
import { useAnalytics } from '../../../hooks/use-analytics';
import { findByAlias } from '../../../routes';
import { NavLink } from 'react-router-dom';
import ServerContext from '../../../contexts/serverContext';

interface Props {
    content: FindASolutionFragment;
    analyticsEventName?: string;
}

export const FindASolution = (props: Props) => {
    const [fireEvents] = useAnalytics();

    const serverContext = useContext(ServerContext);
    const isLincoln = serverContext.brand.toLowerCase() === 'lincoln';

    return (
        <div className="find-a-solution">
            <SearchBarInput
                customId="find-a-solution-search-bar-"
                hideSearchBarTitle={true}
                searchContent={props.content?.searchContent}
            />

            <div className="search-links">
                {props.content?.links?.map(searchLink => {
                    const tileTitle = searchLink.title.includes('Recalls');

                    let recallsSpaTileUrl;
                    if (isLincoln) {
                        recallsSpaTileUrl = findByAlias('RecallsPage');
                    }

                    return tileTitle ? (
                        <div className="find-a-solution-smart-tiles-container">
                            <NavLink
                                key={searchLink.url}
                                className="find-a-solution-smart-tiles"
                                title={searchLink.title}
                                to={
                                    recallsSpaTileUrl
                                        ? recallsSpaTileUrl
                                        : searchLink.url
                                }
                                aria-label={searchLink.ariaLabel}
                                onClick={() => {
                                    props.analyticsEventName &&
                                        fireEvents(
                                            props.analyticsEventName,
                                            undefined,
                                            {
                                                smashHomeClick: {
                                                    articleName:
                                                        searchLink.title,
                                                },
                                            },
                                            false
                                        );
                                }}
                            >
                                <span className="recalls-tile-icon">
                                    <img
                                        src={
                                            process.env.REACT_APP_AEM_BASE_URL +
                                            searchLink.icon
                                        }
                                        alt="recalls-tile-icon"
                                        height={32}
                                        width={32}
                                    />
                                </span>
                                <div className="smart-tiles-content">
                                    {searchLink.title}
                                </div>
                            </NavLink>
                            <hr className="divider" />
                        </div>
                    ) : (
                        <div
                            key={searchLink.url}
                            className="search-link-container"
                        >
                            <SecondaryButton
                                link={searchLink.url}
                                linkTarget={
                                    searchLink.targetBlank ? '_blank' : ''
                                }
                                className="search-link"
                                noChevron={true}
                                aria-label={searchLink.ariaLabel}
                                onClick={() => {
                                    props.analyticsEventName &&
                                        fireEvents(
                                            props.analyticsEventName,
                                            undefined,
                                            {
                                                smashHomeClick: {
                                                    articleName:
                                                        searchLink.title,
                                                },
                                            },
                                            false
                                        );
                                }}
                            >
                                <LinkWithIcon
                                    icon={searchLink.icon}
                                    title={searchLink.title}
                                />
                            </SecondaryButton>
                            <hr className="divider" />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
